import { useState, useEffect, Fragment } from "react";

import "./styles.css";

import {
  RegisterStateObserver,
  ChangeState,
} from "Interfaces/IntroInterface.js";

import { LogoInstagram, LogoFacebook, LogoYoutube } from "./svg";

import BackgroundLogin from "../../Assets/backgroundLogin.png";
import BackgroundLogin2 from "../../Assets/backgroundLogin2.png";
import BackgroundLoginDetail from "../../Assets/backgroungLoginDetail.png";
import Logo from "../../Assets/metazonia.png";
import LogoCassina from "../../Assets/logo-cassina.png";
import LogoManaus from "../../Assets/logo-Manaus.png";
import DetailMobile from "Assets/detailsmenu.png";

import { SignInUser } from "Services/Firebase.js";

function Login() {
  const [opened, setOpened] = useState(true);
  const [formEmail, setFormEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function OpenLogin() {
    setOpened(true);
  }

  function CloseLogin() {
    setOpened(false);
  }

  function OnSubmitLoginForm(event){
    event.preventDefault();

    SignInUser(formEmail, OnSubmitLoginSuccess, OnSubmitLoginFail);
  }

  function OnSubmitLoginSuccess(){
    ChangeState("LoginToFinish");
    setErrorMessage("");
  }

  function OnSubmitLoginFail(){
    setErrorMessage("E-mail não cadastrado");
  }

  useEffect(() => {
    RegisterStateObserver("LoginToRegister", CloseLogin);
    RegisterStateObserver("RegisterToLogin", OpenLogin);
    RegisterStateObserver("LoginToFinish", CloseLogin);
  }, []);

  return (
    <Fragment>
      {opened ? (
        <div className="login">
          <div className="login_card">
          <div className="card_logo_mobile">
          <div className="logo_metazonia_mobile">
              <img src={Logo} alt="" />
            </div>
          </div>
            <div className="logo_metazonia">
              <img src={Logo} alt="" />
            </div>
            <div className="title">
              Bem-vindo!
              <div className="subtitle">Faça o login da sua conta aqui.</div>
            </div>
            <div className="input">
              <form onSubmit={OnSubmitLoginForm} style={{width: '100%'}}>
                <input type="email" placeholder="E-mail cadastrado" onChange={(e) => setFormEmail(e.target.value)} required />
                <button type="submit"
                  // onClick={() => {
                  //   ChangeState("LoginToFinish");
                  // }}
                  className="button_input"
                >
                  acessar
                </button>
              </form>
              <div className="subtitle">{errorMessage}</div>
              <div className="cardboard" onClick={() => {window.location = "https://vr.metazonia.com.br"}} >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 10.5C2.5 9.96957 2.71071 9.46086 3.08579 9.08579C3.46086 8.71071 3.96957 8.5 4.5 8.5H20.5C21.0304 8.5 21.5391 8.71071 21.9142 9.08579C22.2893 9.46086 22.5 9.96957 22.5 10.5V17.5C22.5 18.0304 22.2893 18.5391 21.9142 18.9142C21.5391 19.2893 21.0304 19.5 20.5 19.5H16.632C16.2866 19.5 15.9471 19.4106 15.6466 19.2404C15.3461 19.0702 15.0947 18.8252 14.917 18.529L13.357 15.929C13.2681 15.7811 13.1425 15.6588 12.9923 15.5739C12.8421 15.489 12.6725 15.4443 12.5 15.4443C12.3275 15.4443 12.1579 15.489 12.0077 15.5739C11.8575 15.6588 11.7319 15.7811 11.643 15.929L10.083 18.529C9.90531 18.8252 9.65395 19.0702 9.3534 19.2404C9.05286 19.4106 8.71337 19.5 8.368 19.5H4.5C3.96957 19.5 3.46086 19.2893 3.08579 18.9142C2.71071 18.5391 2.5 18.0304 2.5 17.5V10.5ZM4.313 7.281C4.67819 6.73329 5.17291 6.28418 5.75327 5.9735C6.33364 5.66282 6.98171 5.50018 7.64 5.5H17.358C18.0165 5.50001 18.6647 5.66257 19.2453 5.97326C19.8258 6.28395 20.3207 6.73315 20.686 7.281L21.5 8.5H3.5L4.313 7.281V7.281Z"
                    stroke="#00A49D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>&ensp;

                <div>Acessar com VR / Cardboard</div>
              </div>
            </div>
            <div className="cadastrar">
              <div className="title">Ainda não tem uma conta?</div>
              <div
                className="button"
                onClick={() => {
                  ChangeState("LoginToRegister");
                }}
              >
                Crie uma conta para acessar
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="text">
              Bernardo Ramos e, R. Gov. Vitório - Centro, Manaus - AM, 69036-110
            </div>
            <div className="images">
              <img src={LogoCassina} alt="" />
              <img src={LogoManaus} alt="" />
            </div>
            <div className="logos">
                <a href="https://www.instagram.com/casaraodainovacaocassina/" target="_blank">
                <LogoInstagram/>
                </a>
                <a href=" https://www.facebook.com/SemtepiManaus" target="_blank">
                <LogoFacebook/>
                </a>
                <a href=" https://youtube.com/c/PrefeituradeManausOficial" target="_blank">
                <LogoYoutube/>
                </a>
            </div>
            <div className="detail_mobile">
            <img src={DetailMobile}/>
          </div>
          </div>

          <div className="image_background_detail">
            <img src={BackgroundLoginDetail} alt="" />
            <img src={BackgroundLoginDetail} alt="" />
          </div>
          <div className="image_background">
            <img src={BackgroundLogin2} alt="" />
          </div>
          <div className="image_background">
            <img src={BackgroundLogin} alt="" />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default Login;
